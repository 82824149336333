/* rubik-300 - latin */
@font-face {
     font-family: 'Rubik';
     font-style: normal;
     font-weight: 300;
     src: url('/fonts/rubik-v21-latin-300.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-300.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-300.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-regular - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: normal;
     font-weight: 400;
     src: url('/fonts/rubik-v21-latin-regular.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-500 - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: normal;
     font-weight: 500;
     src: url('/fonts/rubik-v21-latin-500.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-500.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-500.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-600 - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: normal;
     font-weight: 600;
     src: url('/fonts/rubik-v21-latin-600.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-600.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-600.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-700 - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: normal;
     font-weight: 700;
     src: url('/fonts/rubik-v21-latin-700.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-700.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-700.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-800 - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: normal;
     font-weight: 800;
     src: url('/fonts/rubik-v21-latin-800.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-800.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-800.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-900 - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: normal;
     font-weight: 900;
     src: url('/fonts/rubik-v21-latin-900.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-900.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-900.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-300italic - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: italic;
     font-weight: 300;
     src: url('/fonts/rubik-v21-latin-300italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-300italic.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-300italic.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-italic - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: italic;
     font-weight: 400;
     src: url('/fonts/rubik-v21-latin-italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-italic.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-italic.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-500italic - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: italic;
     font-weight: 500;
     src: url('/fonts/rubik-v21-latin-500italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-500italic.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-500italic.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-600italic - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: italic;
     font-weight: 600;
     src: url('/fonts/rubik-v21-latin-600italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-600italic.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-600italic.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-700italic - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: italic;
     font-weight: 700;
     src: url('/fonts/rubik-v21-latin-700italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-700italic.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-700italic.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-800italic - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: italic;
     font-weight: 800;
     src: url('/fonts/rubik-v21-latin-800italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-800italic.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-800italic.svg#Rubik') format('svg'); /* Legacy iOS */
   }
   /* rubik-900italic - latin */
   @font-face {
     font-family: 'Rubik';
     font-style: italic;
     font-weight: 900;
     src: url('/fonts/rubik-v21-latin-900italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/fonts/rubik-v21-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/rubik-v21-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/rubik-v21-latin-900italic.woff') format('woff'), /* Modern Browsers */
          url('/fonts/rubik-v21-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/rubik-v21-latin-900italic.svg#Rubik') format('svg'); /* Legacy iOS */
   }
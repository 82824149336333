@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme-color-1: #ffffff;
  --theme-color-2: #7aabde;
  --theme-color-3: #22507a;
  --theme-color-4: #2b2a29;
  --theme-color-5: #03B3DD;
  --theme-color-6: #E2E2E2;
  --theme-color-7: #808080;
  --theme-color-8: #F7F7F7;
}

.theme-c1 {
  color: var(--theme-color-1);
}
.theme-c2 {
  color: var(--theme-color-2);
}
.theme-c3 {
  color: var(--theme-color-3);
}
.theme-c4 {
  color: var(--theme-color-4);
}
.theme-c5 {
  color: var(--theme-color-5);
}
.theme-c6 {
  color: var(--theme-color-6);
}
.theme-c7 {
  color: var(--theme-color-7);
}
.theme-c8 {
  color: var(--theme-color-8);
}

.theme-b1 {
  background-color: var(--theme-color-1);
}
.theme-b2 {
  background-color: var(--theme-color-2);
}
.theme-b3 {
  background-color: var(--theme-color-3);
}
.theme-b4 {
  background-color: var(--theme-color-4);
}
.theme-b5 {
  background-color: var(--theme-color-5);
}
.theme-b6 {
  background-color: var(--theme-color-6);
}
.theme-b7 {
  background-color: var(--theme-color-7);
}
.theme-b8 {
  background-color: var(--theme-color-8);
}

html {
  @apply scroll-smooth;
}

html,
body {
  font-family: "Rubik",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 25px;
  @apply font-normal overflow-x-hidden p-0 m-0 antialiased scroll-smooth;

  &.no-scroll {
    @apply overflow-hidden;
  }
}

.griglia {
  @apply grid gap-12;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

p {
  @apply mb-4;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

body {
  font-size:15px;

  @apply theme-c7;
}

h1, h2, h3, h4, h5, h6 {
  @apply theme-c4;
}

h1 {
  @apply text-3xl md:text-7xl font-semibold;
}

h2 {
  @apply text-3xl md:text-6xl font-semibold;
}

h3 {
  @apply text-3xl md:text-4xl font-semibold;
}

h4 {
  @apply text-2xl md:text-3xl font-semibold;
}

a {
  @apply theme-c2 transition-all duration-300;

  &:hover {
    @apply theme-c3;
  }
}

section {
  @apply px-4 md:px-0;
}

#introslide-row {
  margin-top:110px;
  @apply px-0;
}

.mainNav {
  height:110px;
  background:rgba(255, 255, 255, 0.97);
  z-index:30;
  @apply shadow-md px-12 fixed top-0 left-0 w-full hidden lg:block;

  .Logo {
    @apply flex;
  }

  .navBar {
    @apply flex h-full items-center font-normal p-0 m-0;

    li,
    li > div,
    li .link-block {
      @apply flex h-full items-center;
    }

    button,
    a {
      @apply h-full flex justify-start items-center px-6 theme-c2 transition-all duration-300;

      svg {
        @apply w-4 h-4 ml-3 theme-c2 transition-all duration-300;
      }
    }

    button:hover,
    a:hover,
    button.isOpen,
    a.isOpen,
    button.active,
    a.active {
      @apply theme-c3;

      svg {
        @apply theme-c3;
      }
    }

    .dropdown-panel {
      min-width: 255px;
      background:rgba(255, 255, 255, 0.97);
      @apply absolute z-10 top-full left-0 shadow-xl;

      &-inner {
        padding-top: 1px;

        @apply gap-0;

        a {
          margin-bottom: 1px;
          
          @apply py-4 w-full;

          &.active,
          &:hover {
            background-color: rgba(0, 0, 0, 0.06);
          }
        }
      }

      .dropdown-panel {
        top: -1px;
        right: calc(-100% - 1px);
        @apply left-auto;
      }
    }
  }

  #languages {
    button {
      @apply px-3 py-2;
    }
    .lang {
      a {
        @apply block theme-b8 px-3 py-2;
  
        &:hover {
          @apply theme-b2;
        }
      }
  
    }
  }
}

.mainNavMobile {
  height:60px;
  background:rgba(255, 255, 255, 0.97);
  z-index:30;
  @apply shadow-md px-4 lg:px-12 fixed top-0 left-0 w-full block lg:hidden;

  .Logo {
    @apply hidden lg:flex;
  }
  .MiniLogo {
    @apply flex lg:hidden items-center;
  }

  a {

    &.active {
      @apply theme-c3;
    }
  }
}

body {
  position: relative;
  overflow-x: visible;
  left:0px;
  @apply transition-all duration-300;

  &.slide-left {
    left:-125px;
  }
}

#burger {
  @apply  w-6 h-4 flex flex-col justify-between cursor-pointer;

  &:before, 
  &:after,
  span {
    content:'';
    height:2px;
    @apply block theme-b2 transition-all duration-300;
  }

  &:hover {
    &:before, 
    &:after,
    span {
      @apply theme-b7;
    }
  }
}

#menu_mobile {
  z-index:30;
  width:250px;
  right:-260px;
  z-index:9003;
  @apply block h-full fixed top-0 theme-b1 transition-all duration-300;

  &.active {
    @apply left-auto right-0;
  }

  ul {
    @apply pl-0 list-none;
  }
}

#menu_mobile_overlay {
  background: rgba(0,0,0,.0);
  z-index: 9002;
  @apply fixed top-0 left-0 w-full h-screen hidden transition-all duration-300;

  &.active {
    background: rgba(0,0,0,.6);
    @apply block;
  }
}

.navBarMobile_head {
  @apply flex justify-end px-4 items-center;

  .closeBtn {
    height:60px; 

    @apply w-6 cursor-pointer flex items-center;

    &:after{
      display: inline-block;
      content: "\00d7";
      @apply theme-c7 text-5xl font-extralight transition-all duration-300;

    }

    &:hover:after {
      @apply theme-c6;
    }
  }
}

.navBarMobile {
  width: 100%!important;

  li {
    width: 100%;
    position: relative;
    border-top: 1px solid rgba(0,0,0,.03);
    
    .link-block {
      @apply flex justify-between items-center theme-c2 transition-all duration-300;

      span {
        font-size:20px;
        @apply h-full w-8 text-center cursor-pointer;
      }
    }

    a, button {
      font-size:13px;
      @apply flex grow justify-between items-center px-6 leading-4 h-12 theme-c2 transition-all duration-300;
    }
    
  }

  .dropdown-panel {
    min-width: 255px;
    background-color: rgba(0, 0, 0, 0.03);
    @apply relative;

    &-inner {
      padding-top: 1px;

      @apply gap-0;

      & > div {
        border-top: 1px solid rgba(0,0,0,.03);

        a {
          margin-bottom: 1px;
          
          @apply py-2 pl-12 w-full leading-4 h-12;
  
          &.active,
          &:hover {
            background-color: rgba(0, 0, 0, 0.06);
          }
        }
      }

    }
  }

  #languages {
    @apply pl-3;

    button {
      @apply block w-full text-left;
    }
    
    // .absolute {
    //   background: rgba(255,255,255,.025);
    //   @apply relative;

    //   a {
    //     border-top: 1px solid rgba(0,0,0,.03);
    //     @apply bg-transparent;
    //   }
    // }
  }
}

#introslide {
  .swiper-autoheight .swiper-wrapper {
    height: auto !important;
  }

  .slide {
    aspect-ratio: 160 / 75;
    @apply  bg-center bg-cover relative;

    img {
      @apply scale-75 opacity-0 transition-all duration-500 object-cover;
    }

    &::after {
      content:'';
      background: rgb(90, 200, 250);
      @apply absolute bottom-0 left-0 h-1 w-0;
    }

    .caption {
      @apply absolute top-0 left-0 w-full h-full flex items-center justify-start transition-all duration-1000 delay-200 scale-75 opacity-0;

      h3 {
        background-color: rgba(81, 81, 81, 0.73);
        @apply inline-block z-10 h-auto w-auto theme-c1 whitespace-nowrap min-h-0 min-w-0 p-4;
        line-height: 52px;
        letter-spacing: 1.7333px;
        font-weight: 500;
        font-size: 52px;
      }
    }

    &.swiper-slide-active {
      @apply w-full transition-all duration-500;

      img {
        @apply scale-100 opacity-100;
      }

      &::after {
        @apply w-full transition-all ease-linear;
        transition-duration: 3000ms;
      }

      .caption {
        @apply  scale-100 opacity-100;
      }
    }

    &.fake {
      // aspect-ratio: auto !important;
      // aspect-ratio: 160 / 75;

      @apply flex items-center;
      
      &::after {
        display:none !important;
      }
    }
  }
}

.btn {
  @apply inline-flex px-8 py-3 font-bold transition-all duration-300;
}

.btn-theme2 {
  @apply btn theme-b2 theme-c1;

  &:hover {
    @apply theme-b5;
  }

  svg {
    @apply mr-4;
  }
}

.btn-theme3 {
  @apply btn theme-b3 theme-c1;

  &:hover {
    @apply theme-b6 theme-c4;
  }
}

.btn-outline {
  border-color:var(--theme-color-6);
  @apply btn theme-b1 theme-c4 border-2;

  &:hover {
    border-color:var(--theme-color-5);
    @apply theme-b5 theme-c1;
  }
}

#introhome {
  // padding-top:60px;
  // padding-top:110px;

  @apply px-12 lg:px-24 2xl:px-36 py-8;

  .griglia {
    @apply gap-0 block lg:grid;
  }

  .imageContainer {
    @apply flex justify-center;
  }


  .text {
    @apply flex items-center px-12 text-center;

    & > div {
      @apply w-full;
    }

    h1 {
      line-height: 57px;
      letter-spacing: 0px;
      font-weight: 600;
      // font-size: 52px;

      @apply mt-8 mb-16 relative transition-all duration-1000 translate-y-12 opacity-0 text-3xl 3xl:text-6xl;
      transition-delay: 400ms;

      &::before {
        content:'';
        width:61px;
        height:107px;
        background:url('/images/bozimex-home-2.png') top left no-repeat;
        background-size: contain;
        perspective: 600px;
        transform: rotateY(0deg);
        transition-delay: 700ms;
        @apply hidden md:block absolute -top-12 -left-4 lg:-left-32 transition-all duration-1000 ease-out opacity-0;
      }
    }

    p {
      @apply  transition-all duration-1000 translate-y-12 opacity-0;
    }

    p:nth-of-type(1) {
      border-bottom:1px solid var(--theme-color-6);
      transition-delay: 500ms;

      @apply flex justify-center pb-4 xl:pb-8 mb-8;

      a:nth-of-type(1) {
        @apply btn-theme2 md:mx-8 whitespace-nowrap;
      }
      
      a:nth-of-type(2) {
        @apply btn-theme3 md:mx-8 whitespace-nowrap;
      }
    }

    p:nth-of-type(2) {
      transition-delay: 700ms;

      @apply flex flex-col items-center;

      &::after {
        content:'';
        width:84px;
        height:48px;
        background:url('/images/telefona.png') top left no-repeat;
        background-size: contain;
        @apply block my-4 xl:my-8;
      }
    }

    p:nth-of-type(3) {
      transition-delay: 900ms;

      // @apply flex justify-center pb-16 md:mb-16 flex-wrap;
      @apply flex justify-center flex-wrap;

      a {
        @apply text-xl md:text-xl theme-c3 mx-8 font-medium whitespace-nowrap;
      }
    }
  }

  .text {
    @apply relative;
  }

  #down_btn {
    width:61px;
    height:92px;
    z-index:1000;
    background:url('/images/bozimex-home-giu.png') top left no-repeat;
    background-size: contain;
    transition-delay: 900ms;
    @apply hidden md:block absolute top-1/2 left-0 lg:-left-16 transition-all duration-1000 ease-out opacity-0 translate-y-12 cursor-pointer;
  }
}

.swiper-slide-active {
  #introhome {  
    h1, p {
      @apply   translate-y-0 opacity-100;
    }

    h1 {
      &::before {
        transform: rotateY(720deg);
        @apply opacity-100;
      }
    }

    #down_btn {
        content:'';
        @apply translate-y-10 opacity-100;
      }
  }
}

section#services h2,
#home-section-3 h2 {
  @apply pt-24 relative;

  &::before {
    content:'';
    width:31px;
    height:46px;
    background:url('/images/finance2-pic1-1.png') top left no-repeat;
    background-size: contain;
    perspective: 600px;
    transform: rotateY(0deg);
    transition-delay: 700ms;
    @apply absolute top-0 left-0;
  }
}

section#services {
  background-image:url('/images/finance2-sectionbg1.png');
  background-position: top center;
  @apply my-0 py-24 bg-no-repeat;

  h2 {
    @apply mb-16;
  }

  .card-group {
    &.griglia {
      @apply grid-cols-1 md:grid-cols-2 lg:grid-cols-4;
    }

    .card {
      @apply flex flex-col justify-end;

      .card-btn {
        @apply btn-outline mt-8;
      }
    }
  }

  h5 {
    font-size: 17px;
    line-height: 27px;
    @apply font-bold pb-8;
  }
}

#home-section-3 {
  background-image:url('/images/finance2-wrapbg1.png');
  background-position: top center;
  @apply my-16 py-24 bg-no-repeat text-center md:text-left;

  h2 {
    @apply mb-16;
  }
  
  .griglia {
    width:100%;
    @apply block md:flex flex-wrap gap-0;

    & > div:nth-of-type(1) {
      @apply w-full;
    }
  }

  h3 {
    @apply text-3xl lg:text-6xl font-semibold pl-4 mt-8 md:mt-0 text-center md:text-left;
  }

  .text {
    @apply h-auto #{!important}
  }

  #counter {
    span {
      min-width:auto;
      font-size: 50px;
      @apply block theme-c2 font-bold text-center md:text-left;
    }
  }
}

#home-section-4 {
  background-image:url('/images/finance2-sectionbg2.jpg');
  background-position: top center;
  @apply my-16 py-24 bg-no-repeat;

  .griglia {
    @apply gap-0 md:gap-8 block md:grid;

    .image {
      @apply mb-8 md:mb-0;
    }
  }

  .text {
    @apply text-center md:text-right flex items-center relative;

    a {
      @apply btn-theme2 mt-8;
    }

    h2 {
      @apply relative mb-8;

      &::before {
        content:'';
        width:31px;
        height:46px;
        background:url('/images/finance2-pic2-1.png') top left no-repeat;
        background-size: contain;
        perspective: 600px;
        transform: rotateY(0deg);
        transition-delay: 700ms;
        @apply absolute -top-24 right-0;
      }
    }
  }
}

#home-section-5 {
  background-image:url('/images/finance2-sectionbg3.png');
  background-position: top center;
  @apply my-16 py-24 bg-no-repeat relative;

  h3 {
    font-size: 35px;
    line-height: 45px;
    font-weight: 600;
    @apply mb-4 relative;
  }

  .griglia {
    @apply block md:grid text-center md:text-left;

    & > div {
      @apply py-8 md:py-0;
    }

    & > div:nth-of-type(1) {
      .text {
        @apply  relative;
    
        &::before {
          content:'';
          width:31px;
          height:46px;
          background:url('/images/finance2-pic1-1.png') top left no-repeat;
          background-size: contain;
          perspective: 600px;
          transform: rotateY(0deg);
          transition-delay: 700ms;
          @apply absolute -top-16 left-0;
        }
      }
    }
  }

  h5 {
    font-size: 17px;
    line-height: 27px;
    font-weight: 300;
    @apply theme-c4;
  }

  h6 {
    font-size: 24px;
    font-weight: 600;
    @apply theme-c4 mb-4;
  }

  .text {
    a {
      @apply btn-theme2 mt-8;
    }
  }

  &::after {
    content:'';
    left:30%;
    // margin-left:-15%;
    width:40%;
    border-bottom:1px solid #C6C6C6;
    @apply absolute bottom-0;
  }
}

#home-section-6 {
  @apply py-24 relative;

  .griglia {
    @apply block md:grid text-center md:text-left;
  }

  h2 {
    @apply mb-8;
  }

  #text-1667576932 {
    a {
      @apply btn-theme2 mt-8;
    }
  }

  #last_blog {
    @apply mt-0;
  }
}

footer {
  @apply theme-b8;

  #footer {
    @apply  py-16;

    .griglia {
      @apply block md:grid text-center md:text-left;
    }
  }

  .image {
    @apply py-8 md:py-0;
  }

  .griglia {
    & > div:nth-of-type(1) {
      .text {
        border-bottom:1px solid #C6C6C6;
        @apply flex flex-col md:flex-row pb-16 items-center;

        &::before {
          content:'';
          width:84px;
          height:48px;
          background:url('/images/telefona.png') top left no-repeat;
          background-size: contain;
          @apply block mr-8 mb-4 md:mb-0;
        }
      }
    }
  }

  h6 {
    font-size: 12px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 3px;
  }

  h4 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    letter-spacing: 0px;
    @apply theme-c3 mt-4;
  }

  .externalBtn {
    @apply btn-theme3;
  }

  .link {
    @apply flex justify-center py-8 md:py-0 md:justify-end;
  }

  #subfooter {
    @apply theme-b1 py-8 text-sm md:text-base text-center md:text-left;
  }
}

main.innerpage {
  padding-top:110px;
}

#about-section-1 {
  @apply px-8 lg:px-32 py-12 text-center md:text-left;

  .griglia {
    @apply block md:columns-2 gap-16;
  }

  .col-span-1:nth-of-type(2) {
    @apply break-after-column;
  }

  h1 {
    border-bottom: 1px solid #E2E2E2;
    @apply lg:pt-24 pb-12 mb-12 text-3xl lg:text-7xl;
  }

  h6 {
    font-size: 12px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 3px;
    @apply theme-c4 mb-4;
  }

  .attachment {
    @apply flex justify-center my-12;

    a {
      @apply btn-theme2;
    }
  }

  #image-1667836204 {
    @apply mt-12 md:mt-0;
  }

}

#about-section-2 {
  background-image:url('/images/finance2-sectionbg1.png');
  background-position: top center;
  @apply my-0 py-24 bg-no-repeat;

  .griglia {
    @apply block lg:grid;

    .image {
      @apply mb-12 lg:mb-0;
    }
  }
  
  h5 {
      font-size: 17px;
      line-height: 27px;
      font-weight: 300;
      letter-spacing: 0px;

      @apply mb-4;
  }
}

#about-section-3 {
  background-image:url('/images/finance2-sectionbg2.jpg');
  background-position: top center;
  @apply my-0 py-24 bg-no-repeat;

  h2 {
      @apply mb-8;
  }

  .griglia {
    @apply block lg:grid;

    .text {
      @apply mb-12 lg:mb-0;
    }
  }
}

#team {
  background-image:url('/images/finance2-sectionbg3.png');
  background-position: top center;
  @apply bg-no-repeat relative py-16;

  .griglia {
    @apply grid-cols-1 lg:grid-cols-3 gap-0 mb-20;
  }

  h4 {
    @apply mb-8 text-center lg:text-left;
  }

  h6 {
    @apply my-4 text-3xl font-semibold;
  }

  .card {
    @apply  mb-12 text-center lg:text-left;

    .image {
      @apply flex justify-center lg:justify-start
    }
  }

  .card-links {
    @apply mt-2 flex flex-col items-center lg:items-start;
  }

  .card-link {
    @apply flex items-center;

    svg {
      fill:var(--theme-color-3);
      @apply w-4 h-4 mr-8;
    }
  }

  #logistics_for_scrap {
    border:1px solid var(--theme-color-3);
    @apply rounded-3xl flex items-center p-8;

    p {
      @apply pb-4;
    }
  }
}

#intro-section {
  @apply  px-8 lg:px-32 py-12 text-center md:text-left;

  .griglia {
    @apply block lg:grid;
  }

  .text {
    @apply justify-center lg:justify-start;
  }

  h1 {
    @apply pb-12 mb-12 text-3xl lg:text-7xl text-center lg:text-left;
  }

  .text {
    @apply flex items-center;
  }

}

#corporate-section-2 {
  background-image:url('/images/finance2-sectionbg1.png');
  background-position: top center;
  @apply py-12 text-center md:text-left bg-no-repeat;

  .griglia {
    @apply block lg:columns-2 gap-16;
  }

  .col-span-1:nth-of-type(2) {
    @apply break-after-column;
  }

  h1 {
    border-bottom: 1px solid #E2E2E2;
    @apply pb-12 mb-12 text-3xl lg:text-7xl;
  }

  h2 {
    @apply mb-8 relative pt-24;

    &::before {
      content:'';
      width:31px;
      height:46px;
      background:url('/images/finance2-pic2-1.png') top left no-repeat;
      background-size: contain;
      perspective: 600px;
      transform: rotateY(0deg);
      transition-delay: 700ms;
      @apply absolute top-0 right-0;
    }
  }

  h6 {
    font-size: 12px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 3px;
    @apply theme-c4 mb-4;
  }

  .attachment {
    @apply flex justify-center my-12;

    a {
      @apply btn-theme2;
    }
  }

  .image {
    @apply mb-12;
  }

  #image-1667901592 {
    @apply relative pt-24;

    &::before {
      content:'';
      width:31px;
      height:46px;
      background:url('/images/finance2-pic1-1.png') top left no-repeat;
      background-size: contain;
      perspective: 600px;
      transform: rotateY(0deg);
      transition-delay: 700ms;
      @apply absolute top-0 left-0;
    }
  }
}

#corporate-section-2b {
  // background-image:url('/images/finance2-sectionbg1.png');
  // background-position: top center;
  @apply py-12 text-center md:text-left bg-no-repeat;

  & > .container > div {
    @apply block lg:columns-2 gap-16;
  }
}

#corporate-section-3 {
  @apply py-32;

  .griglia {
    @apply block lg:grid gap-16;
  }

  #image-1667902424 {
    @apply relative pt-24;

    &::before {
      content:'';
      width:31px;
      height:46px;
      background:url('/images/finance2-pic1-1.png') top left no-repeat;
      background-size: contain;
      perspective: 600px;
      transform: rotateY(0deg);
      transition-delay: 700ms;
      @apply absolute top-0 left-0;
    }
  }

  h2 {
    @apply mb-8;
  }

  #text-1667902427 {
    @apply relative pt-32;

    &::before {
      content:'';
      width:31px;
      height:46px;
      background:url('/images/finance2-pic1-1.png') top left no-repeat;
      background-size: contain;
      perspective: 600px;
      transform: rotateY(0deg);
      transition-delay: 700ms;
      @apply absolute top-12 left-0;
    }
  }
}

#corporate-section-4 {
  @apply py-32;

  h2 {
    @apply mb-16;
  }

  .griglia {
    @apply block lg:grid gap-16;
  }
}

[data-rmiz-content="found"] img {
  @apply cursor-pointer #{!important};
}

#certification-section {
  background-image:url('/images/finance2-sectionbg3.png');
  background-position: top center;
  @apply bg-no-repeat pt-0 lg:pt-24;

  .griglia {
    @apply block lg:grid;
  }

  #certifications {
    &.griglia {
      @apply block md:grid grid-cols-2;
    }
  }

  .card-btn {
    @apply btn-theme2 my-12 w-full text-center;
  }

}

#authorizations-section {
  @apply pt-0 lg:pt-24;

  .griglia {
    @apply block lg:grid;
  }

  #authorizations {
    &.griglia {
      @apply block md:grid grid-cols-2;
    }
  }

  .card-btn {
    @apply btn-theme2 my-12 w-full text-center;
  }
}

ul {
  @apply list-disc pl-4;
}


#quality-section-1 {
  @apply pt-0 lg:pt-24;
}

#quality-section-2 {
  background-image:url('/images/finance2-sectionbg1.png');
  background-position: top center;
  @apply bg-no-repeat;
  @apply pt-8 lg:pt-24;

  .griglia {
    @apply block md:columns-2 gap-32;
  }

  .image {
    @apply my-12 break-after-column;
  }
}

#quality-section-3 {
  background-image:url('/images/finance2-sectionbg2.jpg');
  background-position: top center;
  @apply my-16 my-12 py-12 lg:my-24 lg:py-24 bg-no-repeat;

  .griglia {
    @apply block lg:grid gap-32;
  }

  .text {
    @apply flex items-center;
  }
}

main#services,
main#servizi {
  #intro-section {
    #text-1667920923 {
      border-top:1px solid #C6C6C6;
      @apply pt-12 block md:flex justify-between;

      &::after {
        content:'';
        width:84px;
        height:48px;
        background:url('/images/telefona.png') top left no-repeat;
        background-size: contain;
        background-position: center;
        @apply flex justify-center my-8 mx-auto md:mx-0;
      }
    }
  }

  #card-group-sections {
    @apply block md:grid grid-cols-2 pb-24;

    .card {
      @apply mt-12 md:mt-0;

      a {
        @apply h-full flex flex-col justify-between;
      }

      h3 {
        @apply mb-8;
      }
    }
  }

}

// #contacts,
// #demolitions,
// #waste-management-services,
// #services-for-recovery-recycling-plants,
// #servizi-per-gli-impianti-di-recupero-riciclo,
// #services-for-the-steel-industry,
// #servizi-per-lindustria-siderurgica {

main.service_theme {
  @apply pt-32;

  #intro-section {
    #text-1667921986 {
      & > div {
        @apply w-full;
      }

      p:nth-of-type(1) {
        border-top:1px solid #C6C6C6;
        @apply pt-12 pr-24 relative;

        &::after {
          content:'';
          width:84px;
          height:48px;
          background:url('/images/telefona.png') top left no-repeat;
          background-size: contain;
          background-position: center;
          @apply hidden md:block absolute top-12 right-0;
        }

        a {
          @apply text-xl md:text-xl theme-c3 font-medium whitespace-nowrap;
        }
      }
    }
  }

  h3 {
    @apply mb-8 text-center md:text-left;
  }

  .text {
    @apply flex items-center text-center md:text-left;
  }

  .image {
    @apply  my-8 md:my-0;
  }

  section:nth-of-type(1) {
    @apply mb-0 #{!important};
  }

  section {
    @apply mb-32;
  }

  .griglia {
    @apply block md:grid gap-32;
  }
}

#contatti,
#contacts {
  h1 {
    @apply pb-0 mb-4 text-center md:text-left;
  }

  h5 {
    @apply mb-12 text-center md:text-left justify-center md:justify-start;
  }

  .form-fld {
    @apply my-4 md:my-0;
  }
}

.card-group#gallery {
  @apply grid-cols-1 md:grid-cols-3 xl:grid-cols-6 gap-8 my-12;

  .image {
    img {
      @apply object-cover aspect-square object-center;

    }
  }
}

#demolitions,
#demolizioni {
  .card-group#gallery {
    @apply grid-cols-1 md:grid-cols-3 xl:grid-cols-4;
  }

  h4 {
    @apply mb-8;
  }

  #section-1667922005 {
    .griglia {
      @apply block md:columns-2 gap-32;

      .text {
        @apply break-after-column;
      }
    }
  }

}

.youtube {
  aspect-ratio: 560 / 315;
  @apply mb-16 relative;

  iframe {
    @apply w-full absolute top-0 left-0 h-full;
  }
}

main#video,
main#videos {
  @apply pt-48;

  h2 {
    @apply mb-12;

  }
}

.blog_row {
  @apply block md:grid grid-cols-3 mb-12 gap-8;

  .blog_img {
    border:4px solid var(--theme-color-8);
    @apply col-span-1 aspect-video flex items-center justify-center relative overflow-hidden mb-8;

    img {
      @apply transition-all duration-300 translate-y-0 z-10;
    }

    .overlay {
      @apply absolute theme-b3 opacity-0 bottom-0 left-0 w-full h-full transition-all duration-300 pointer-events-none z-20;
    }

    .console {
      @apply theme-b2 h-16 absolute -bottom-16 left-0 w-full transition-all duration-300 grid grid-cols-2 gap-0 z-30;

      & > a,
      & > div {
        @apply flex justify-center items-center;

        svg {
          fill:var(--theme-color-1);
          @apply w-6 h-6 transition-all duration-300;
        }

        &:hover {
          svg {
            fill:var(--theme-color-3);
          }
        }
      }
    }

    &:hover {
      img {
        @apply  -translate-y-4;
      }

      .overlay {  
        @apply opacity-10;
      }

      .console {
        @apply bottom-0;
      }
    }
  }
  
  .blog_txt {
    @apply col-span-2 flex flex-col justify-between break-words;

    p {
      @apply mt-8;
    }
  }

}

#contatti,
#contacts {
  .griglia {
    @apply gap-12 #{!important};
  }

  #image-1668002847, 
  #image-1668004031 {
    border:2px solid var(--theme-color-6);
  }

  #sedi-1 {
    background-image:url('/images/finance2-sectionbg2.jpg');
    background-position: top center;
    @apply my-16 py-24 bg-no-repeat;

    #image-1668002893 {
      @apply relative;

      &::before {
        content:'';
        width:31px;
        height:46px;
        background:url('/images/finance2-pic1-1.png') top left no-repeat;
        background-size: contain;
        perspective: 600px;
        transform: rotateY(0deg);
        transition-delay: 700ms;
        @apply absolute -top-24 left-0;
  
      }
    }
  }

  #sedi-1,
  #section-1668004508,
  #section-1668004006,
  #section-1668004304,
  #form-section {
    .text {
      & > div {
        @apply w-full;
      }
    }

  }

  #image-1668004031,
  #image-1668004328,
  #image-1668004328 {
    @apply relative;

    .imageContainer {
      @apply h-full;

      img {
        @apply object-cover h-full;  
      }
    }

  }

  #link-1668004512 {
    @apply flex justify-center;

    a {
      @apply btn-theme2;
    }
  }

  #form-section {
    background-image:url('/images/finance2-wrapbg1.png');
    background-position: top center;
    @apply my-16 py-24 bg-no-repeat text-center md:text-left;

    .griglia {
      @apply items-start;
    }

    h5 {
      @apply mb-8 text-xl flex items-center;

      &:before {
        content:'';
        width:48px;
        height:32px;
        background-image: url('/images/finance2-contact-icon5.png');
        @apply bg-cover mr-4;
      }
    }

    label,
    input[type=radio] {
      @apply cursor-pointer;
    }
  }
}

.form-contact {
  input[type=text], textarea {
    border-bottom:1px solid var(--theme-color-3);
    @apply theme-b1 px-4 py-3;

    &:focus {
      border-color: var(--theme-color-5);
      @apply outline-none shadow-none ring-transparent;
    }
  }
}

.share {
  border-top:1px solid #EEEEEE;
  @apply flex py-8;

  & > div {
    @apply ml-4;

    svg { 
      width:21px;
      height:18px;
      fill:var(--theme-color-7);
      @apply transition-all duration-300;
    }
  }

  div:nth-of-type(1) {
    button:hover,
    a:hover {
      svg {
        fill:#4267B2;
      }
    }
  }
  div:nth-of-type(2) {
    button:hover,
    a:hover {
      svg {
        fill:#1DA1F2;
      }
    }
  }
  div:nth-of-type(3) {
    button:hover,
    a:hover {
      svg {
        fill:#0e76a8;
      }
    }
  }
  div:nth-of-type(4) {
    button:hover,
    a:hover {
      svg {
        fill:#E60023;
      }
    }
  }
}

// LightBox
.lb-container {
  background-color: rgba(0,0,0,0.4) #{!important};
}

#section-1667922005 {
  .griglia {
    @apply block md:grid;
  }
}

// Iubenda
#iubenda_policy {
  @apply py-16;

  h1 {
    @apply text-5xl;
  }

  h2 {
    @apply text-3xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }
}

section#condizioni {
  ol {
    @apply list-decimal pl-12;
  }
}

#reserved-area {
  @apply pb-36 pt-64;

  .card-group.griglia {
    @apply grid-cols-2 gap-36;

    .card {
      @apply theme-b8 p-12 text-center flex flex-col justify-between;

      h3 {
        @apply theme-c3 pb-8;
      }

      .cardContent {
        @apply pb-8;
      }

      .card-btn {
        @apply btn-theme3;
      }

    }

  }
}



@media (max-width: 768px) {
  section {
    @apply text-center;
    ul {
      @apply text-left;
    }
  }
  img {
    @apply mx-auto;
  }
}

@media (min-width: 768px) {
  #home-section-3 {
    #counter {
      span {
        min-width:480px;
        font-size: 110px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .mainNavMobile {
    height:110px;
  }
  
}

@media (max-width: 1024px) {

  #introslide-row {
    margin-top:60px;
  }

  // #image-1667492750 {
  //   @apply hidden;
  // }

  #introhome .text p:nth-of-type(1) {
    border:none;
  }

  #introhome .text p:nth-of-type(2),
  #introhome .text p:nth-of-type(3) {
    display:none;
  }

  #introslide {
    .slide,
    .slide.fake {
        aspect-ratio: auto;

        @apply flex justify-center items-center h-auto;
    }

    .slide {
      .caption {
        @apply justify-center w-auto;
  
        h3 {
          line-height: 32px;
          letter-spacing: 1.7333px;
          font-weight: 500;
          font-size: 29px;
  
          @apply text-center whitespace-normal;
        }
      }
    }
    .swiper-autoheight .swiper-wrapper {
      align-items: stretch;
    }
  }
}

@media (min-width: 1100px) {
  #home-section-3 {
    .griglia {
      width:70%;
    }
  }

  .container {
    width:1100px;
  }
}
